import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import FormContainer from '../components/FormContainer';
import { currencies } from '../components/Currencies';
import ProductForm from '../components/ProductForm';
import { createorupdateProductDiscount } from '../actions/discountActions';
import { PRODUCT_CREATE_RESET } from '../constants/productConstants';
import { createProduct } from '../actions/productActions';

const ProductCreateScreen = () => {
	const navigate = useNavigate();

	const dispatch = useDispatch();

	const [name, setName] = useState('');
	const [price, setPrice] = useState(0);
	const [currency, setCurrency] = useState('INR');
	const [image, setImage] = useState('');
	const [brand, setBrand] = useState('');
	const [countInStock, setCountInStock] = useState(0);
	const [description, setDescription] = useState('');
	const [showDiscount, setShowDiscount] = useState(false);

	const [isDiscountActive, setIsDiscountActive] = useState(false);

	const [discountValue, setDiscountValue] = useState(0);
	const [discountUnit, setDiscountUnit] = useState('percentage');
	const [validUntil, setValidUntil] = useState('');
	const [couponCode, setCouponCode] = useState('');

	const [uploading, setUploading] = useState(false);
	const [mainCategory, setMainCategory] = useState([]);
	const [mainCategorySelected, setMainCategorySelected] = useState([]);
	const [subCategory, setSubCategory] = useState([]);
	const [subCategorySelected, setSubCategorySelected] = useState([]);
	const [thirdCategory, setThirdCategory] = useState([]);
	const [thirdCategorySelected, setThirdCategorySelected] = useState([]);
	const [fourthCategory, setFourthCategory] = useState([]);
	const [fourthCategorySelected, setFourthCategorySelected] = useState([]);
	const [fifthCategory, setFifthCategory] = useState([]);
	const [fifthCategorySelected, setFifthCategorySelected] = useState([]);
	const [categoryId, setCategoryId] = useState(0);
	const [isClear, setIsClear] = useState(0);
	const [sizes, setSizes] = useState([]);
	const clothes_sizes = [
		'S',
		'M',
		'L',
		'XL',
		'XXL',
		'3XL',
		'4XL',
		'5XL',
		'6XL',
		'7XL',
	];
	const shoes_sizes = ['6', '7', '8', '9', '10'];

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const productCreate = useSelector((state) => state.productCreate);
	const { loading, error, success, product } = productCreate;

	const uploadFileHandler = async (e) => {
		const files = e.target.files;
		const formData = new FormData();
		formData.append('userId', userInfo._id);

		//console.log(files);
		//console.log(...files);
		[...files].forEach((file) => {
			formData.append('image', file);
		});

		setUploading(true);

		try {
			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
			};
			const { data } = await axios.post('/api/upload', formData, config);
			setImage(data);
			setUploading(false);
		} catch (error) {
			console.error(error);
			setUploading(false);
		}
	};

	const getFirstLevelCategoryData = async (e) => {
		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`,
			},
		};
		const { data } = await axios.get('/api/category/firstlevel', config);
		setMainCategory(data);
	};
	if (mainCategory.length === 0) getFirstLevelCategoryData();

	const firstCategorySelectedHandler = async (first_Category_selected) => {
		if (first_Category_selected.length !== 0) {
			setMainCategorySelected(first_Category_selected);

			try {
				const config = {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${userInfo.token}`,
					},
				};
				const { data } = await axios.get(
					`/api/category/secondlevel/${first_Category_selected}`,
					config
				);
				if (data && data.length > 0) {
					// Check if data is valid
					setSubCategory(data);
				} else {
					setSubCategory([]); // Set to empty array if no valid data
				}
			} catch (error) {
				console.error(error);
			}
		} else {
			setMainCategorySelected([]);
			setSizes([]);
			setIsClear(1);
		}
	};

	const secondCategorySelectedHandler = async (second_Category_selected) => {
		if (second_Category_selected.length !== 0) {
			setSubCategorySelected(second_Category_selected);
			try {
				const config = {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${userInfo.token}`,
					},
				};
				const { data } = await axios.get(
					`/api/category/thirdlevel/${second_Category_selected}/${mainCategorySelected}`,
					config
				);
				if (data && data.length > 0) {
					// Check if data is valid
					setThirdCategory(data);
				} else {
					setThirdCategory([]); // Set to empty array if no valid data
				}
			} catch (error) {
				console.error(error);
			}
		} else {
			setSubCategorySelected([]);
			setIsClear(1);
		}
	};

	const thirdCategorySelectedHandler = async (third_Category_selected) => {
		if (third_Category_selected.length !== 0) {
			setThirdCategorySelected(third_Category_selected);
			try {
				const config = {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${userInfo.token}`,
					},
				};
				const { data } = await axios.get(
					`/api/category/fourthlevel/${third_Category_selected}/${subCategorySelected}`,
					config
				);
				if (data && data.length > 0) {
					// Check if data is valid
					setFourthCategory(data);
				} else {
					setFourthCategory([]); // Set to empty array if no valid data
				}
			} catch (error) {
				console.error(error);
			}
		} else {
			setThirdCategorySelected([]);
			setIsClear(1);
		}
	};

	const fourthCategorySelectedHandler = async (fourth_Category_selected) => {
		if (fourth_Category_selected.length !== 0) {
			setFourthCategorySelected(fourth_Category_selected);
			try {
				const config = {
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${userInfo.token}`,
					},
				};
				const { data } = await axios.get(
					`/api/category/fifthlevel/${fourth_Category_selected}/${thirdCategorySelected}`,
					config
				);
				if (data && data.length > 0) {
					// Check if data is valid
					setFifthCategory(data);
				} else {
					setFifthCategory([]); // Set to empty array if no valid data
				}
			} catch (error) {
				console.error(error);
			}
		} else {
			setFourthCategorySelected([]);
			setIsClear(1);
		}
	};

	const fifthCategorySelectedHandler = async (fifth_Category_selected) => {
		if (fifth_Category_selected.length !== 0) {
			setFifthCategorySelected(fifth_Category_selected);
		} else {
			setFifthCategorySelected([]);
			setIsClear(1);
		}
	};

	const sizesSelectedHandler = async (user_sizes_selected) => {
		if (user_sizes_selected.length !== 0) {
			setSizes(user_sizes_selected);
		} else {
			setSizes([]);
			setIsClear(1);
		}
	};
	const getCategoryId = async (e) => {
		const all_categories = {};
		try {
			if (mainCategorySelected) {
				//console.log(mainCategorySelected);
				all_categories['mainCategory'] = mainCategorySelected[0];
			}
			if (subCategorySelected) {
				all_categories['subCategory'] = subCategorySelected[0];
			}
			if (thirdCategorySelected) {
				all_categories['thirdCategory'] = thirdCategorySelected[0];
			}
			if (fourthCategorySelected) {
				all_categories['fourthCategory'] = fourthCategorySelected[0];
			}
			if (fifthCategorySelected) {
				all_categories['fifthCategory'] = fifthCategorySelected[0];
			}
			//console.log(all_categories);
			const { data } = await axios.get(`/api/category/getcategoryid/`, {
				params: {
					query: JSON.stringify(all_categories),
				},
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${userInfo.token}`,
				},
			});
			//console.log(data);
			setCategoryId(data);
		} catch (error) {
			console.error(error);
		}
	};
	const handleDiscountChange = (checked) => {
		setShowDiscount(checked);
		setIsDiscountActive(checked);
	};

	const isValidDiscountDate = (date) => {
		const today = new Date();
		const tomorrow = new Date();
		tomorrow.setDate(today.getDate() + 1); // Set to tomorrow

		return new Date(date) >= tomorrow; // Check if the date is at least tomorrow
	};

	const validateCategories = () => {
		if (mainCategorySelected.length === 0) {
			return false;
		}
		if (
			Array.isArray(subCategory) &&
			subCategory.some((item) => item !== undefined && item !== null) &&
			subCategorySelected.length === 0
		) {
			return false;
		}
		if (
			Array.isArray(thirdCategory) &&
			thirdCategory.some((item) => item !== undefined && item !== null) &&
			thirdCategorySelected.length === 0
		) {
			return false;
		}
		if (
			Array.isArray(fourthCategory) &&
			fourthCategory.some((item) => item !== undefined && item !== null) &&
			fourthCategorySelected.length === 0
		) {
			return false;
		}
		if (
			Array.isArray(fifthCategory) &&
			fifthCategory.some((item) => item !== undefined && item !== null) &&
			fifthCategorySelected.length === 0
		) {
			return false;
		}
		return true;
	};

	const validateForm = () => {
		if (!name) {
			alert('Please enter the product name.');
			return false;
		}
		if (!price) {
			alert('Please enter the product price.');
			return false;
		}
		if (!currency) {
			alert('Please select a currency.');
			return false;
		}
		if (!image) {
			alert('Please upload an image.');
			return false;
		}
		if (!brand) {
			alert('Please enter the brand.');
			return false;
		}
		if (countInStock === undefined || countInStock === null) {
			alert('Please enter the count in stock.');
			return false;
		}
		if (!validateCategories()) {
			alert('Please fill all category and subcategory levels.');
			return false;
		}
		if (!sizes.length) {
			alert('Please select available sizes.');
			return false;
		}
		if (!description) {
			alert('Please enter the product description.');
			return false;
		}
		return true;
	};

	const submitHandler = (e) => {
		e.preventDefault();
		if (validateForm()) {
			// Check if validUntil is set and validate it
			if (showDiscount && validUntil && !isValidDiscountDate(validUntil)) {
				alert('The discount date must be at least one day in advance.');
				return; // Prevent form submission
			}
			//const categoryId = parseInt(finalCategoryId);
			//console.log(categoryId);
			// adding 10% flat on all the products.
			// TODO: after creating category ID's, create rules of margin based on product category
			//const final_price = parseInt(price) + parseInt(0.25 * price);

			dispatch(
				createProduct({
					name,
					price,
					currency,
					brand,
					description,
					countInStock,
					image,
					categoryId,
					sizes,
				})
			);

			if (userInfo.role === 'Admin') {
				navigate('/admin/productlist');
			} else {
				navigate('/seller/productlist');
			}
		} else {
			alert('Please fill all category and subcategory levels.');
		}
	};

	useEffect(() => {
		if (success) {
			dispatch({ type: PRODUCT_CREATE_RESET });
			const newProductId = product._id;
			// If showDiscount is checked, create a discount associated with the new product
			if (showDiscount) {
				dispatch(
					createorupdateProductDiscount({
						product_id: newProductId,
						discount_value: discountValue,
						discount_unit: discountUnit,
						valid_until: validUntil,
						coupon_code: couponCode,
					})
				);
			}
		} else {
			if (!isClear && product) {
				//alert('in useeffect');
				//setMainCategory(all_categories.category);
				setName(product.name);
				setPrice(product.price);
				setCurrency(product.currency);
				setImage(product.image);
				setBrand(product.brand);
				setCountInStock(product.countInStock);
				setSizes(product.sizes);
				setDescription(product.description);
			}
		}
	}, [
		dispatch,
		navigate,
		product,
		userInfo,
		mainCategory,
		isClear,
		couponCode,
		discountUnit,
		discountValue,
		showDiscount,
		success,
		validUntil,
	]);

	return (
		<>
			<Link to='/admin/productlist' className='btn btn-light my-3'>
				Go Back
			</Link>
			<FormContainer>
				<h1 data-title='Create Product'>Create Product</h1>
				{loading && <Loader />}
				{error && <Message variant='danger'> {error}</Message>}
				<ProductForm
					name={name}
					setName={setName}
					price={price}
					setPrice={setPrice}
					currency={currency}
					setCurrency={setCurrency}
					image={image}
					setImage={setImage}
					brand={brand}
					setBrand={setBrand}
					countInStock={countInStock}
					setCountInStock={setCountInStock}
					description={description}
					setDescription={setDescription}
					showDiscount={showDiscount}
					setShowDiscount={setShowDiscount}
					isDiscountActive={isDiscountActive}
					setIsDiscountActive={setIsDiscountActive}
					discountValue={discountValue}
					setDiscountValue={setDiscountValue}
					discountUnit={discountUnit}
					setDiscountUnit={setDiscountUnit}
					validUntil={validUntil}
					setValidUntil={setValidUntil}
					couponCode={couponCode}
					setCouponCode={setCouponCode}
					uploading={uploading}
					uploadFileHandler={uploadFileHandler}
					mainCategory={mainCategory}
					mainCategorySelected={mainCategorySelected}
					setMainCategorySelected={setMainCategorySelected}
					subCategory={subCategory}
					subCategorySelected={subCategorySelected}
					setSubCategorySelected={setSubCategorySelected}
					thirdCategory={thirdCategory}
					thirdCategorySelected={thirdCategorySelected}
					setThirdCategorySelected={setThirdCategorySelected}
					fourthCategory={fourthCategory}
					fourthCategorySelected={fourthCategorySelected}
					setFourthCategorySelected={setFourthCategorySelected}
					fifthCategory={fifthCategory}
					fifthCategorySelected={fifthCategorySelected}
					setFifthCategorySelected={setFifthCategorySelected}
					sizes={sizes}
					setSizes={setSizes}
					clothes_sizes={clothes_sizes}
					shoes_sizes={shoes_sizes}
					handleDiscountChange={handleDiscountChange}
					submitHandler={submitHandler}
					getCategoryId={getCategoryId}
					isEdit={false}
					product_created_at={null}
					product_updated_at={null}
					previousSelectedCategory={[]}
					previousSelectedSubcategory={[]}
					previousSelectedThirdcategory={[]}
					previousSelectedFourthcategory={[]}
					previousSelectedFifthcategory={[]}
					previousSizesSelected={[]}
					firstCategorySelectedHandler={firstCategorySelectedHandler}
					secondCategorySelectedHandler={secondCategorySelectedHandler}
					thirdCategorySelectedHandler={thirdCategorySelectedHandler}
					fourthCategorySelectedHandler={fourthCategorySelectedHandler}
					fifthCategorySelectedHandler={fifthCategorySelectedHandler}
					sizesSelectedHandler={sizesSelectedHandler}
					currencies={currencies}
					loadingDiscount={false}
					errorDiscount={null}
					productId={null}
				/>
			</FormContainer>
		</>
	);
};

export default ProductCreateScreen;
