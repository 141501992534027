import React, { useEffect } from 'react';
import { Row, Col, Card, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FaUser, FaBuilding, FaUserTie } from 'react-icons/fa';

const RegistrationChoice = ({ location }) => {
	const redirect = '/';

	useEffect(() => {
		// Apply styles to html and body when the component mounts
		document.documentElement.style.height = '100%';
		document.body.style.height = '100%';
		document.body.style.margin = '0';
		document.body.style.padding = '0';
		document.body.style.overflow = 'auto'; // Allow scrolling

		// Clean up styles when the component unmounts
		return () => {
			document.documentElement.style.height = '';
			document.body.style.height = '';
			document.body.style.margin = '';
			document.body.style.padding = '';
			document.body.style.overflow = '';
		};
	}, []);

	return (
		<div className='registration-page'>
			<Container fluid className='registration-container'>
				<Row className='justify-content-center w-100'>
					<Col xs={12} className='text-center mb-4'>
						<h1 className='display-4'>Register as</h1>
					</Col>
					<Col xs={12} md={6} lg={4} className='mb-4'>
						<Card className='text-center shadow-sm h-100'>
							<Card.Body className='d-flex flex-column align-items-center justify-content-center'>
								<FaUser size={50} className='mb-3 text-primary' />
								<Card.Title>Buyer</Card.Title>
								<Link
									to={redirect ? `/register?redirect=${redirect}` : '/register'}
								>
									<Button variant='primary' className='mt-3 registerchoice'>
										Register
									</Button>
								</Link>
							</Card.Body>
						</Card>
					</Col>
					<Col xs={12} md={6} lg={4} className='mb-4'>
						<Card className='text-center shadow-sm h-100'>
							<Card.Body className='d-flex flex-column align-items-center justify-content-center'>
								<FaBuilding size={50} className='mb-3 text-success' />
								<Card.Title>Seller (Company)</Card.Title>
								<Link
									to={
										redirect
											? `/register_seller?redirect=${redirect}`
											: '/register'
									}
								>
									<Button variant='success' className='mt-3 registerchoice'>
										Register
									</Button>
								</Link>
							</Card.Body>
						</Card>
					</Col>
					<Col xs={12} md={6} lg={4} className='mb-4'>
						<Card className='text-center shadow-sm h-100'>
							<Card.Body className='d-flex flex-column align-items-center justify-content-center'>
								<FaUserTie size={50} className='mb-3 text-warning' />
								<Card.Title>Individual Seller</Card.Title>
								<Link
									to={
										redirect
											? `/register_seller?type=individual&redirect=${redirect}`
											: '/register'
									}
								>
									<Button variant='warning' className='mt-3 registerchoice'>
										Register
									</Button>
								</Link>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default RegistrationChoice;
