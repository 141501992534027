import React, { useEffect } from 'react';
import axios from 'axios';
import { LinkContainer } from 'react-router-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import { Table, Button, Row, Col, Nav } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import Paginate from '../components/Paginate';
import {
	deleteProduct,
	createProduct,
	listProductsAdmin,
} from '../actions/productActions';
import { PRODUCT_CREATE_RESET } from '../constants/productConstants';

const ProductListScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { pageNumber = 1 } = useParams();

	const productList = useSelector((state) => state.productListAdmin);
	const { loading, error, products, page, pages } = productList;

	const productDelete = useSelector((state) => state.productDelete);
	const {
		loading: loadingDelete,
		error: errorDelete,
		success: successDelete,
	} = productDelete;

	const productCreate = useSelector((state) => state.productCreate);
	const {
		loading: loadingCreate,
		error: errorCreate,
		success: successCreate,
		product: createdProduct,
	} = productCreate;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	useEffect(() => {
		dispatch({ type: PRODUCT_CREATE_RESET });

		if (!userInfo.role === 'Admin') {
			navigate('/login');
		}

		if (successCreate) {
			navigate(`/admin/product/${createdProduct._id}/edit`);
		} else {
			dispatch(listProductsAdmin(pageNumber));
		}
	}, [
		dispatch,
		userInfo,
		successDelete,
		successCreate,
		createdProduct,
		pageNumber,
		navigate,
	]);

	const deleteHandler = (id) => {
		if (window.confirm('Are you sure?')) {
			dispatch(deleteProduct(id));
		}
	};

	const toggleActivationHandler = async (id, currentStatus) => {
		if (
			window.confirm(
				`Are you sure you want to ${
					currentStatus ? 'deactivate' : 'activate'
				} this product?`
			)
		) {
			try {
				await axios.put(`/api/products/${id}/toggle`);
				// Refresh the product list or update the state to reflect the change
				dispatch(listProductsAdmin());
			} catch (error) {
				console.error('There was an error toggling the product status:', error);
			}
		}
	};

	const createProductHandler = () => {
		dispatch(createProduct());
	};

	return (
		<>
			<Row className='align-items-center'>
				<Col>
					<h1>Products</h1>
				</Col>
				<Col className='text-right'>
					<Button className='my-3' onClick={createProductHandler}>
						<i className='fas fa-plus'></i> Create Product
					</Button>
				</Col>
			</Row>
			{loadingDelete && <Loader />}
			{errorDelete && <Message variant='danger'>{errorDelete}</Message>}
			{loadingCreate && <Loader />}
			{errorCreate && <Message variant='danger'>{errorCreate}</Message>}
			{loading ? (
				<Loader />
			) : error ? (
				<Message variant='danger'>{error}</Message>
			) : (
				<>
					<Table striped bordered hover responsive className='table-sm'>
						<thead>
							<tr>
								<th>ID</th>
								<th>NAME</th>
								<th>PRICE</th>
								<th>CATEGORY</th>
								<th>BRAND</th>
								<th>COUNT IN STOCK</th>
								<th>STATUS</th>
								<th>ACTIONS</th>
							</tr>
						</thead>
						<tbody>
							{products.map((product) => (
								<tr key={product._id}>
									<td>{product._id}</td>
									<td>
										<LinkContainer to={`/product/${product._id}`}>
											<Nav.Link>{product.name}</Nav.Link>
										</LinkContainer>
									</td>
									<td>
										{new Intl.NumberFormat('en-US', {
											style: 'currency',
											currency: product.currency,
										}).format(product.price)}
									</td>
									<td>{product.category}</td>
									<td>{product.brand}</td>
									<td>{product.countInStock}</td>
									<td>{product.status ? 'Active' : 'Inactive'}</td>
									<td>
										<div className='d-flex justify-content-around'>
											<LinkContainer to={`/admin/product/${product._id}/edit`}>
												<Button variant='light' className='btn-sm'>
													<i className='fas fa-edit'></i>
												</Button>
											</LinkContainer>
											<Button
												variant={product.status ? 'danger' : 'success'}
												className='btn-sm'
												onClick={() =>
													toggleActivationHandler(product._id, product.status)
												}
											>
												<i
													className={
														product.status ? 'fas fa-times' : 'fas fa-check'
													}
												></i>
											</Button>
											<Button
												variant='danger'
												className='btn-sm'
												onClick={() => deleteHandler(product._id)}
											>
												<i className='fas fa-trash'></i>
											</Button>
										</div>
									</td>
								</tr>
							))}
						</tbody>
					</Table>
					<Paginate pages={pages} page={page} isAdmin={true} />
				</>
			)}
		</>
	);
};

export default ProductListScreen;
