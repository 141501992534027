import { useEffect, useRef } from 'react';
import crypto from 'crypto-js';
import axios from 'axios';
import { ORDER_PAY_FAIL, ORDER_PAY_SUCCESS } from '../constants/orderConstants';
import { removeFromCart } from '../actions/cartActions';
import { useDispatch } from 'react-redux';

// Function to load script and append in DOM tree.
const loadScript = (src) =>
	new Promise((resolve) => {
		const script = document.createElement('script');
		script.src = src;
		script.async = true;
		script.onload = () => {
			console.log('razorpay loaded successfully');
			resolve(true);
		};
		script.onerror = () => {
			console.log('error in loading razorpay');
			resolve(false);
		};
		document.body.appendChild(script);
	});

const RenderRazorpay = ({
	orderId,
	orderIdRazor,
	keyId,
	keySecret,
	currency,
	amount,
	userInfo,
}) => {
	const paymentId = useRef(null);
	const dispatch = useDispatch();
	const paymentMethod = useRef(null);

	useEffect(() => {
		// Check if keyId and keySecret are loaded properly
		if (!keyId || !keySecret) {
			console.error('Razorpay key ID or secret is not defined');
			return;
		}

		// To load razorpay checkout modal script.
		const displayRazorpay = async (options) => {
			const res = await loadScript(
				'https://checkout.razorpay.com/v1/checkout.js'
			);

			if (!res) {
				console.log('Razorpay SDK failed to load. Are you online?');
				return;
			}

			// All information is loaded in options which we will discuss later.
			const rzp1 = new window.Razorpay(options);

			// If you want to retrieve the chosen payment method.
			rzp1.on('payment.submit', (response) => {
				paymentMethod.current = response.method;
			});

			// To get payment id in case of failed transaction.
			rzp1.on('payment.failed', (response) => {
				paymentId.current = response.error.metadata.payment_id;
			});

			// to open razorpay checkout modal.
			rzp1.open();
		};

		const options = {
			key: keyId, // key id from props
			amount, // Amount in lowest denomination from props
			currency, // Currency from props.
			name: 'Sow Trendy', // Title for your organization to display in checkout modal
			// image, // custom logo url
			order_id: orderIdRazor, // order id from props
			// This handler method is always executed in case of succeeded payment
			handler: (response) => {
				console.log('succeeded');
				//console.log(response);
				paymentId.current = response.razorpay_payment_id;

				// Most important step to capture and authorize the payment. This can be done on Backend server.
				const succeeded =
					crypto
						.HmacSHA256(
							`${orderIdRazor}|${response.razorpay_payment_id}`,
							keySecret
						)
						.toString() === response.razorpay_signature;

				// If successfully authorized. Then we can consider the payment as successful.
				if (succeeded) {
					handlePayment('succeeded', {
						orderIdRazor,
						paymentId: paymentId.current,
						signature: response.razorpay_signature,
					});
				} else {
					handlePayment('failed', {
						orderIdRazor,
						paymentId: response.razorpay_payment_id,
					});
				}
			},
			modal: {
				confirm_close: true, // this is set to true, if we want confirmation when clicked on cross button.
				// This function is executed when checkout modal is closed
				// There can be 3 reasons when this modal is closed.
				ondismiss: async (reason) => {
					const {
						reason: paymentReason,
						field,
						step,
						code,
					} = reason && reason.error ? reason.error : {};
					// Reason 1 - when payment is cancelled. It can happen when we click cross icon or cancel any payment explicitly.
					if (reason === undefined) {
						console.log('cancelled');
						handlePayment('Cancelled');
					}
					// Reason 2 - When modal is auto closed because of timeout
					else if (reason === 'timeout') {
						console.log('timedout');
						handlePayment('timedout');
					}
					// Reason 3 - When payment gets failed.
					else {
						console.log('failed');
						handlePayment('failed', {
							paymentReason,
							field,
							step,
							code,
						});
					}
				},
			},
			// This property allows to enable/disable retries.
			// This is enabled true by default.
			retry: {
				enabled: false,
			},
			timeout: 900, // Time limit in Seconds
			theme: {
				color: '', // Custom color for your checkout modal.
			},
		};

		displayRazorpay(options);
		// eslint-disable-next-line
	}, [keyId, keySecret]);

	// informing server about payment
	const handlePayment = async (status, orderDetails = {}) => {
		if (status === 'succeeded') {
			const config = {
				headers: {
					Authorization: `Bearer ${userInfo.token}`,
				},
			};
			const { data } = await axios.put(
				`/api/orders/${orderId}/RazorPay/pay`,
				{
					status,
					orderDetails,
				},
				config
			);
			try {
				dispatch({
					type: ORDER_PAY_SUCCESS,
					payload: data,
				});
				await axios.get(
					`/api/orders/email/buyer/complete_order/${orderId}`,
					config
				);
				await axios.get(
					`/api/orders/email/seller/complete_order/${orderId}`,
					config
				);
				await axios.get(
					`/api/orders/email/distributors
					/complete_order/${orderId}`,
					config
				);
				dispatch(removeFromCart());
			} catch (error) {
				dispatch({
					type: ORDER_PAY_FAIL,
					payload:
						error.response && error.response.data.message
							? error.response.data.message
							: error.message,
				});
			}
		} else {
			dispatch({
				type: ORDER_PAY_FAIL,
				payload: 'Order Cancelled',
			});
		}
	};

	return null;
};

export default RenderRazorpay;
