import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { register } from '../actions/userActions';
import FormContainer from '../components/FormContainer';
import { currencies } from '../components/Currencies';
//import { countries } from '../components/Countries';

const RegisterScreenSeller = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const [name, setName] = useState('');
	const [buisnessRegistrationCode, setBuisnessRegistrationCode] = useState('');
	const [bank_account, setBankAccount] = useState('');
	const [phone, setPhone] = useState('');
	const [message, setMessage] = useState(null);
	const [currency, setCurrency] = useState('USD');

	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const userRegister = useSelector((state) => state.userRegister);
	const { loading, error, userInfo } = userRegister;
	const redirect = location.search ? location.search.split('=')[1] : '/';
	const type = new URLSearchParams(location.search).get('type');

	useEffect(() => {
		if (userInfo) {
			navigate(redirect);
		}
	}, [navigate, userInfo, redirect]);
	const submitHandler = (e) => {
		e.preventDefault();
		if (password !== confirmPassword) {
			setMessage('Passwords to not match');
		} else {
			let role = 'Buyer'; // Default role
			if (bank_account && buisnessRegistrationCode) {
				role = 'Seller-Company';
			} else if (bank_account) {
				role = 'Seller-Individual';
			}
			dispatch(
				register(
					name,
					phone,
					email,
					currency,
					role,
					buisnessRegistrationCode,
					bank_account,
					password
				)
			);
		}
	};

	return (
		<FormContainer>
			<h1>Sign Up</h1>
			{message && <Message variant='danger'>{message}</Message>}
			{error && <Message variant='danger'>{error}</Message>}
			{loading && <Loader />}
			<Form onSubmit={submitHandler}>
				<Form.Group controlId='name' className='mb-3'>
					<Form.Label>Name</Form.Label>
					<Form.Control
						type='name'
						placeholder='Enter Name'
						value={name}
						onChange={(e) => setName(e.target.value)}
					></Form.Control>
				</Form.Group>

				<Form.Group controlId='phone' className='mb-3'>
					<Form.Label>Mobile Number</Form.Label>
					<Form.Control
						type='phone'
						placeholder='Enter Mobile No.'
						value={phone}
						onChange={(e) => setPhone(e.target.value)}
					></Form.Control>
				</Form.Group>

				<Form.Group controlId='email' className='mb-3'>
					<Form.Label>Email Address</Form.Label>
					<Form.Control
						type='email'
						placeholder='Enter Email'
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						autoComplete='email'
					></Form.Control>
				</Form.Group>

				<Form.Group controlId='currency' className='mb-3'>
					<Form.Label>Choose Country</Form.Label>
					<Form.Control
						as='select'
						value={currency}
						onChange={(e) => setCurrency(e.target.value)}
					>
						{Object.keys(currencies)
							.filter((item) => currencies[item].code === 'INR')
							.map((item) => {
								return (
									<option
										value={currencies[item].code}
										key={currencies[item].code}
									>
										{currencies[item].country} - {currencies[item].code} (
										{currencies[item].symbol})
									</option>
								);
							})}
					</Form.Control>
				</Form.Group>

				<Form.Group controlId='bank_account' className='mb-3'>
					<Form.Label>Bank Account Number</Form.Label>
					<Form.Control
						type='bank_account'
						placeholder='Enter Bank Account Number'
						value={bank_account}
						maxLength='18'
						onChange={(e) => setBankAccount(e.target.value)}
					></Form.Control>
				</Form.Group>
				{type !== 'individual' ? (
					<Form.Group controlId='buisnessregistrationcode' className='mb-3'>
						<Form.Label>GST IN / UIN (15-Digits Code)</Form.Label>
						<Form.Control
							type='buisnessregistrationcode'
							placeholder='GST IN / UIN
                    '
							value={buisnessRegistrationCode}
							maxLength='15'
							onChange={(e) => setBuisnessRegistrationCode(e.target.value)}
						></Form.Control>
					</Form.Group>
				) : null}

				<Form.Group controlId='password' className='mb-3'>
					<Form.Label>Password</Form.Label>
					<Form.Control
						type='password'
						placeholder='Enter Password'
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						autoComplete='new-password'
					></Form.Control>
				</Form.Group>

				<Form.Group controlId='confirmPassword' className='mb-3'>
					<Form.Label>Re-type Password</Form.Label>
					<Form.Control
						type='password'
						placeholder='Confirm Password'
						value={confirmPassword}
						onChange={(e) => setConfirmPassword(e.target.value)}
						autoComplete='new-password'
					></Form.Control>
				</Form.Group>

				<Button type='submit' variant='primary'>
					Register
				</Button>
			</Form>
			<Row className='py-3'>
				<Col>
					<Form.Text muted>Have an Account? </Form.Text>

					<Link to={redirect ? `login?redirect=${redirect}` : '/login'}>
						Login
					</Link>
				</Col>
			</Row>
		</FormContainer>
	);
};

export default RegisterScreenSeller;
