import React, { useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../components/Message';
import Loader from '../components/Loader';
import { LinkContainer } from 'react-router-bootstrap';
import { deleteUser, listUsers } from '../actions/userActions';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const UserListScreen = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [roles, setRoles] = useState([]);

	const userList = useSelector((state) => state.userList);
	const { loading, error, users } = userList;

	const userLogin = useSelector((state) => state.userLogin);
	const { userInfo } = userLogin;

	const userDelete = useSelector((state) => state.userDelete);
	const { success: successDelete } = userDelete;

	useEffect(() => {
		const fetchRoles = async () => {
			const { data } = await axios.get('/api/roles');
			setRoles(data);
		};

		const fetchData = async () => {
			if (userInfo && userInfo.role === 'Admin') {
				dispatch(listUsers());
				await fetchRoles();
			} else {
				navigate('/login');
			}
		};

		fetchData();
	}, [dispatch, userInfo, navigate, successDelete]);

	const getRoleName = (roleId) => {
		const role = roles.find((role) => role._id === roleId);
		return role ? role.name : 'Unknown';
	};

	const deleteHandler = (id) => {
		if (window.confirm('Are you sure?')) {
			dispatch(deleteUser(id));
		}
	};

	return (
		<>
			<h1>Users</h1>
			{loading ? (
				<Loader />
			) : error ? (
				<Message variant='danger'>{error}</Message>
			) : (
				<Table striped bordered hover responsive className='table-sm'>
					<thead>
						<tr>
							<th>ID</th>
							<th>NAME</th>
							<th>EMAIL</th>
							<th>ROLE</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{users.map((user) => (
							<tr key={user._id}>
								<td>{user._id}</td>
								<td>{user.name}</td>
								<td>
									<a href={`mailto:${user.email}`}>{user.email}</a>
								</td>
								<td>{getRoleName(user.role)}</td>
								<td>
									<LinkContainer to={`/admin/user/${user._id}/edit`}>
										<Button variant='light' className='btn-sm'>
											<i className='fas fa-edit'></i>
										</Button>
									</LinkContainer>
									<Button
										variant='danger'
										className='btn-sm'
										onClick={() => deleteHandler(user._id)}
									>
										<i className='fas fa-trash'></i>
									</Button>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			)}
		</>
	);
};

export default UserListScreen;
